import React from 'react';
import './src/styles/reset.scss';
import './src/styles/variables.scss';
import './src/styles/global.scss';

import { GatsbyBuildWrapElement } from './src/utils/gatsby-build-wrap-element';

export const shouldUpdateScroll = ({ routerProps: { location }, getSavedScrollPosition }) => {
    // hack to override `shouldUpdateScroll` behavior to bypass useQueryParams in product-checkout
    if (location.search.includes('no-scroll=true')) {
        return false;
    }
    const currentPosition = getSavedScrollPosition(location);
    return currentPosition || [0, 0];
};

export const wrapPageElement = ({ element }) => {
    return GatsbyBuildWrapElement(element);
};
